import { memo, useCallback, useMemo, useState } from 'react';
import { useTableDataset } from './useTableDataset';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonKind, Slug, IconKeys, TablePager, Table as GBGTable } from '@gbg/gbgcomponentlibrary_react';
import { PaginatedResponse } from '../../models/paginatedResponse';

interface TableProps {
  title?: string;
  addUrl?: string;
  headers: string[];
  isPaginated?: boolean;
  data: PaginatedResponse<any> | undefined;
  isFetching: boolean;
  searchFields?: string[];
  onItemClicked?: (item: any) => void;
  actions?: (item: any) => any[];
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  titleTag?: keyof JSX.IntrinsicElements;
  addButtonText?: string;
}

const Table: React.FC<TableProps> = ({
  title,
  addUrl,
  headers,
  isPaginated = true,
  data,
  isFetching,
  searchFields = [],
  onItemClicked,
  actions,
  setSearch,
  titleTag = 'h1',
  addButtonText = 'Add',
}: TableProps) => {
  const TitleTag = titleTag;
  const { emptyStateContent, cellContent, headerContent } = useTableDataset(headers);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [internSearch, setInternSearch] = useState<string>('');
  const clickableClass = onItemClicked === undefined ? '' : 'clickable';
  const navigate = useNavigate();

  const searchEnabled = useMemo(() => {
    return setSearch || (searchFields && searchFields.length > 0);
  }, [setSearch, searchFields]);

  const handleSearch = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key !== 'Enter') return;

      // pagination
      if (isPaginated) {
        setCurrentPage(1);
      }

      // search
      const _search = (ev.target as HTMLInputElement).value;
      if (setSearch) {
        setSearch(_search);
      } else {
        setInternSearch(_search.toLowerCase());
      }
    },
    [isPaginated, setSearch],
  );

  const onNext = useCallback(() => {
    if (!isPaginated) return;

    setCurrentPage(prev => prev + 1);
  }, [data, isPaginated]);

  const onPrevious = useCallback(() => {
    if (!isPaginated) return;

    setCurrentPage(prev => prev - 1);
  }, [isPaginated]);

  const handleAdd = useCallback(() => {
    if (addUrl) navigate(addUrl);
  }, [addUrl]);

  const filteredData = useMemo<any[]>(() => {
    if (!data || !data.results || data?.results.length <= 0) return [];

    if (!internSearch?.length) return data.results;

    return data.results.filter((item: { [x: string]: string }) =>
      searchFields.find(field => item[field].toLowerCase().includes(internSearch)),
    );
  }, [data?.results, internSearch, searchFields]);

  return (
    <>
      <div className="row">
        <div className="col-md-6 text-left p-3">
          <TitleTag>{title}</TitleTag>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-4">
          <Button type="submit" kind={ButtonKind.Primary} className=" pull-right" onClick={handleAdd}>
            {addButtonText}
          </Button>
        </div>
        {searchEnabled && (
          <div className="col-md-8 d-grid align-items-center justify-content-end">
            <Slug
              className="pull-right"
              data-testid="search"
              slug={IconKeys.Search}
              placeholder=""
              onKeyUp={handleSearch}
            />
          </div>
        )}
      </div>

      <GBGTable
        data-testid="table"
        className={`w-100 ${clickableClass}`}
        dataSet={{
          emptyStateContent,
          cellContent,
          cellFilter: headers,
          loading: isFetching,
          selectable: false,
          data: filteredData,
          onItemClicked,
          headerContent,
          actions: actions,
        }}
      />

      {isPaginated && !isFetching && data?.totalPages && data.totalPages > 1 && (
        <TablePager
          data-testid="pager"
          totalPages={data?.totalPages || data?.results?.length}
          {...{ currentPage, onNext, onPrevious }}
        />
      )}
    </>
  );
};

export default memo(Table);
