import { useCallback, useMemo } from 'react';
import { JobStatus } from '../../models/job';
import { texts } from '../../app/texts';
import { Pill, PillShape, PillType } from '@gbg/gbgcomponentlibrary_react';

export const useTableDataset = (headerFields: string[]) => {
  const pillType = useCallback((type: JobStatus): PillType => {
    switch (type) {
      case JobStatus.canceled:
        return PillType.Error;
      case JobStatus.mapping:
        return PillType.Warn;
      case JobStatus.processing:
        return PillType.Light;
      case JobStatus.pre_flight_requested:
        return PillType.Dark;
      default:
        return PillType.Success;
    }
  }, []);

  const cellContent = useCallback((key: string, item: any): JSX.Element => {
    if (key?.toLocaleLowerCase().indexOf('status') > -1) {
      return (
        <Pill outlined={true} type={pillType(item[key])} shape={PillShape.Rounded}>
          {item[key]?.toLowerCase()}
        </Pill>
      );
    }

    return item[key];
  }, []);

  const emptyStateContent: JSX.Element = useMemo(
    () => (
      <p className="text-center p-5">
        No data to display
        <br />
      </p>
    ),
    [],
  );

  const headerContent = useMemo(() => new Map<string, string>(headerFields.map(field => [field, texts[field]])), []);

  return { headerContent, emptyStateContent, cellContent };
};
