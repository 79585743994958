import { PreflightResult } from './preflightResult';

export interface Job {
  jobId: string;
  createdAt: string;
  status: JobStatus;
  fileDisplayName: string;
  preFlightResult?: PreflightResult;
}

// For transitions and statuses, please see Dataset Batch Evaluate miro board: https://miro.com/app/board/uXjVMPnnXGg=/
export enum JobStatus {
  'mapping' = 'mapping',
  'pre_flight_requested' = 'pre-flight-requested',
  'pre_flight_complete' = 'pre-flight-complete',
  'evaluate_processing' = 'evaluate-processing',
  'processing' = 'processing',
  'done' = 'done',
  'canceled' = 'canceled',
}
