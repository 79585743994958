import { Dataset, ValidationSchema } from '../models/Dataset';
import { builderApi } from './apiBase';

const endpoint = '/v1/datasets';

const datasetService = builderApi.injectEndpoints({
  endpoints: build => ({
    getDatasets: build.query<Dataset[], void>({
      query: () => ({
        url: `${endpoint}`,
        method: 'GET',
      }),
      providesTags: results =>
        results
          ? [...results.map(item => ({ type: 'Dataset', id: item.id } as const)), { type: 'Dataset', id: 'LIST' }]
          : [{ type: 'Dataset', id: 'LIST' }],
    }),

    getDatasetValidationSchema: build.query<ValidationSchema, { id: number; version: number }>({
      query: ({ id, version }) => ({
        url: `${endpoint}/${id}/versions/${version}/validationschema?resolve-schema=true`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetDatasetsQuery, useLazyGetDatasetValidationSchemaQuery } = datasetService;
