import { Button, ButtonKind } from '@gbg/gbgcomponentlibrary_react';
import React, { memo, useCallback } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { ModalFooterProps, closeModal, handleModalAction } from '../../features/modal/modalSlice';
import { clearError } from '../../features/error/errorSlice';

const ModalFooter: React.FC<ModalFooterProps> = ({
  handleAction,
  actionLabel = 'Remove',
  actionType = ButtonKind.Destructive,
  isSubmitting,
  handleActionData,
  disabled = false,
  cancelLabel = 'Cancel',
}) => {
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(closeModal());
    dispatch(clearError());
  }, [closeModal]);

  const handleDelete = useCallback(() => {
    if (handleAction) {
      dispatch(handleModalAction({ handleActionData, handleAction }));
    }
  }, [handleAction, handleClose, handleActionData]);

  return (
    <>
      {handleAction && (
        <Button
          worker={true}
          active={isSubmitting}
          kind={actionType}
          onClick={handleDelete}
          data-testid="action"
          className="m-m-t-2 m-m-r-1"
          disabled={disabled}
        >
          {actionLabel}
        </Button>
      )}

      <Button kind={ButtonKind.Secondary} className="m-m-t-2 m-m-r-1" onClick={handleClose} data-testid="cancel">
        {handleAction ? cancelLabel : 'OK'}
      </Button>
    </>
  );
};

export default memo(ModalFooter);
