import { memo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { closeModal, selectModal } from '../../features/modal/modalSlice';
import {
  ModalHeader,
  AlertBar,
  IconKeys,
  AlertBarText,
  ModalBody,
  Modal as GBGModal,
} from '@gbg/gbgcomponentlibrary_react';
import ModalFooter from './ModalFooter';

const Modal: React.FC = () => {
  const modal = useAppSelector(selectModal);
  const dispatch = useAppDispatch();
  const modalRef = useRef(null);
  return (
    <GBGModal
      ref={modalRef}
      style={{ zIndex: 9 }}
      className={`modal ${modal.modalClass} ${modal.isOpen ? 'modal-enter-done' : 'modal-exit-done'}`}
      onBackgroundClick={() => {
        dispatch(closeModal);
      }}
    >
      <ModalHeader>
        <AlertBar data-testid="alertBar" icon={IconKeys.AlertTriangle} type={modal.type}>
          <AlertBarText text={modal.header} />
        </AlertBar>
      </ModalHeader>
      <ModalBody data-testid="modal-body">
        <div>{modal.content}</div>
        {modal.renderModalFooter && <ModalFooter {...modal.modalFooter} />}
      </ModalBody>
    </GBGModal>
  );
};

export default memo(Modal);
