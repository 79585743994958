import { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Label, Button, ButtonKind, Select } from '@gbg/gbgcomponentlibrary_react';
import { useGetDatasetsQuery, useLazyGetDatasetValidationSchemaQuery } from '../../api/datasets';
import { usePutProcessStatusMutation, usePutDatasetMappingsMutation } from '../../api/jobs';
import { Dataset, Status, ValidationSchema, ValidationSchemaJSON } from '../../models/Dataset';
import { JobStatus } from '../../models/job';
import DatasetMappingSection from '../../components/input/DatasetMappingSection';
import CustomSpinner from '../../components/CustomSpinner';

export const PreflightJob = () => {
  const navigate = useNavigate();
  const { jobId } = useParams<{ jobId: string }>();
  const { data: datasets, isFetching: isFetchingDatasets } = useGetDatasetsQuery();
  const [selectedDataset, setSelectedDataset] = useState<Dataset | undefined>(undefined);
  const [getDatasetValidationSchema, { isFetching }] = useLazyGetDatasetValidationSchemaQuery();
  const [putDatasetMapping] = usePutDatasetMappingsMutation();
  const [putProcessStatus] = usePutProcessStatusMutation();
  const [JSONValidationSchema, setJSONValidationSchema] = useState<ValidationSchemaJSON | undefined>(undefined);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    unregister,
  } = useForm();

  const options = datasets?.map((dataset: Dataset) =>
    dataset.status === Status.live || dataset.status === Status.testing ? (
      <option key={dataset.id + '-' + dataset.version} value={dataset.id + '-' + dataset.version}>
        {dataset.id} {dataset.description} version {dataset.version}
      </option>
    ) : null,
  );

  const createSchema = (validationSchema: ValidationSchema) => {
    if (!validationSchema.schema) {
      setJSONValidationSchema(undefined);
    } else {
      const validationSchemaJSON = JSON.parse(validationSchema.schema);
      setJSONValidationSchema(validationSchemaJSON);
    }
  };

  const handleChangeDataset = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;
    if (!value) return;

    const selectedDataset = datasets?.find(dataset => dataset.id + '-' + dataset.version === value);
    if (!selectedDataset) return;

    unregister();

    setSelectedDataset(selectedDataset);

    getDatasetValidationSchema({
      id: selectedDataset.id,
      version: selectedDataset.version,
    })
      .unwrap()
      .then((result: ValidationSchema) => {
        createSchema(result);
      });
  };

  const onSubmit = useCallback(
    handleSubmit(async (data: any) => {
      if (!jobId || !selectedDataset) return;

      data.dataset = { id: selectedDataset.id, version: selectedDataset.version };

      // Save the mappings
      let hasError = false;
      await putDatasetMapping({
        jobId: jobId,
        mappings: data,
      })
        .unwrap()
        .catch(() => {
          hasError = true;
        });

      if (hasError) return;
      const validationSchema = await getDatasetValidationSchema({
        id: selectedDataset.id,
        version: selectedDataset.version,
      }).unwrap();

      await putProcessStatus({
        jobId: jobId,
        status: JobStatus.pre_flight_requested,
        datasetValidationSchema: validationSchema.schema,
      })
        .unwrap()
        .catch(() => {
          hasError = true;
        });

      //Return to jobs list once both api calls succeeded
      if (!hasError) navigate('/jobs');
    }),
    [selectedDataset, putDatasetMapping, putProcessStatus, jobId, handleSubmit],
  );

  const returnToJobs = useCallback(() => {
    navigate('/jobs');
  }, []);

  return isFetchingDatasets ? (
    <CustomSpinner />
  ) : (
    <>
      <br />
      <form onSubmit={onSubmit} data-testid="preflight-form">
        <Label>Dataset selection</Label>
        <Select data-testid="dataset" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChangeDataset(e)}>
          <option value="" hidden>
            Select a Dataset...
          </option>
          {options}
        </Select>
        <br />

        <>
          <DatasetMappingSection
            {...{
              data: JSONValidationSchema,
              register,
              errors,
              setValue,
              isFetching: isFetching,
            }}
          ></DatasetMappingSection>

          <br />
          {JSONValidationSchema && (
            <Button type="submit" kind={ButtonKind.Primary} className="pull-left">
              Confirm
            </Button>
          )}
        </>

        <Button type="button" kind={ButtonKind.Secondary} className="float-end" onClick={returnToJobs}>
          Cancel
        </Button>
      </form>
    </>
  );
};

export default memo(PreflightJob);
