import { AlertBarType } from '@gbg/gbgcomponentlibrary_react';
import React, { FC, useEffect } from 'react';
import Modal from './components/modal/Modal';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { openModal } from './features/modal/modalSlice';
import { clearError, selectError } from './features/error/errorSlice';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

const ErrorBoundary: FC<ErrorBoundaryProps> = ({ children }) => {
  const errorState = useAppSelector(selectError);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (errorState.error) {
      dispatch(
        openModal({
          content: errorState.error,
          type: AlertBarType.Error,
          header: 'There was a problem with the request.',
        }),
      );
    }
  }, [errorState.error, dispatch, openModal]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleClearError);
    return () => {
      window.removeEventListener('beforeunload', handleClearError);
    };
  }, []);

  const handleClearError = () => {
    dispatch(clearError());
  };
  return (
    <>
      {children}
      <Modal />
    </>
  );
};

export default ErrorBoundary;
