import React, { useCallback, useState } from 'react';
import { logout, selectPing } from '../features/ping/pingSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import jwt_decode from 'jwt-decode';
import {
  Card,
  CardBody,
  NavItem,
  NavSection,
  PrimaryNavigation,
  TopBar,
  TopBarBrand,
} from '@gbg/gbgcomponentlibrary_react';
import { Route, useNavigate, Routes, Navigate } from 'react-router-dom';
import { routesList } from '../app/routes';
import { env } from '../env';

const Layout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);
  const { accessToken } = useAppSelector(selectPing);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const decodedUserToken = jwt_decode<any>(accessToken);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <div className="structure" data-testid="structure">
      <TopBar
        includeNavLink={true}
        brand={TopBarBrand.GBG}
        user={{ username: decodedUserToken?.username ?? '', company: decodedUserToken?.company_name ?? '' }}
        menu={{
          items: [
            {
              title: 'Log out',
              onSelected: handleLogout,
            },
          ],
        }}
      ></TopBar>

      <PrimaryNavigation
        open={menuOpen}
        onClose={() => {
          setMenuOpen(false);
        }}
      >
        <NavSection>
          <NavItem active={location.pathname === '/jobs'} onClick={() => navigate('/jobs')}>
            Jobs
          </NavItem>
          <NavItem onClick={() => window.location.assign(env.REACT_APP_BUILDER_URL)}>Datasets</NavItem>
        </NavSection>
      </PrimaryNavigation>
      <div className="page__content">
        <Card isPrimaryContent={true}>
          <CardBody isPrimaryContent={true}>
            <Routes>
              <Route path="/" element={<Navigate replace to="/jobs" />} />
              {routesList.map(item => (
                <Route path={item.path} element={item.element} key={item.path}></Route>
              ))}
            </Routes>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Layout;
