import './App.css';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { login, selectPing } from './features/ping/pingSlice';
import jwt_decode from 'jwt-decode';
import { authorize, parseHash } from './features/ping/utils';
import Layout from './components/layout';
import ErrorBoundary from './ErrorBoundary';
interface Token {
  exp: number;
}

function App() {
  const dispatch = useAppDispatch();
  const { accessToken, isAuthenticated } = useAppSelector(selectPing);
  let tokenIsExpired = !accessToken || accessToken === 'EMPTY';
  if (accessToken) {
    const decodedUserToken: Token = jwt_decode(accessToken);
    if (decodedUserToken.exp < Date.now() / 1000) tokenIsExpired = true;
  }

  if (!isAuthenticated || tokenIsExpired) {
    const at = parseHash();
    if (at) {
      dispatch(login(at));
    } else {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      if (!(window as any).loggedOut) {
        authorize();
      }
      /* eslint-enable @typescript-eslint/no-explicit-any */
    }
    return <p>Redirecting...</p>;
  }

  return (
    <>
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    </>
  );
}

export default App;
