import { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Label, ErrorText, ButtonKind } from '@gbg/gbgcomponentlibrary_react';
import ModalFooter from '../../components/modal/ModalFooter';
import { Job, JobStatus } from '../../models/job';
import { useAppDispatch } from '../../app/hooks';
import { closeModal } from '../../features/modal/modalSlice';

export const SubmitToSipForm = ({
  job,
  putProcessStatus,
  isSubmitting,
}: {
  job: Job;
  putProcessStatus: any;
  isSubmitting: boolean;
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();
  const [selectedDataSource, setSelectedDataSource] = useState<string>('');
  const [mode, setMode] = useState<string>('');

  const onSubmit = useCallback(
    handleSubmit(async (data: any) => {
      const { mode, dataSource, customerName, availableForDD } = data;
      const jobData = {
        jobId: job.jobId,
        status: JobStatus.evaluate_processing,
        mode,
        auditConfig: {
          dataSource,
          customerName,
          availableForDD,
        },
      };
      const results = await putProcessStatus(jobData);
      if (results) {
        dispatch(closeModal());
      }
    }),
    [job, putProcessStatus, handleSubmit],
  );

  return (
    <form>
      <div className="form">
        <Label> Please select mode:</Label>
        <select
          {...register('mode', { required: true })}
          className="form-select"
          required
          defaultValue=""
          onChange={e => {
            setMode(e.target.value);
            setValue('mode', e.target.value);
          }}
          data-testid="mode"
        >
          <option value="">-Choose Mode-</option>
          <option value="test">Test</option>
          <option value="live">Live</option>
        </select>
        {errors.mode && <ErrorText>This field is required</ErrorText>}
      </div>
      {mode === 'live' && (
        <div className="audit-config-bock">
          <div className="form-group">
            <Label>Please select data source:</Label>
            <select
              className="form-select"
              required
              defaultValue=""
              {...register('dataSource', { required: true })}
              onChange={e => {
                setSelectedDataSource(e.target.value);
                setValue('dataSource', e.target.value);
              }}
              data-testid="dataSource"
            >
              <option value="">-Choose dataset-</option>
              <option value="trust">Trust</option>
              <option value="customer">Customer</option>
              <option value="other">Other</option>
            </select>
            {errors.dataSource && <ErrorText>This field is required.</ErrorText>}
          </div>

          <div className="form-group">
            {selectedDataSource == 'customer' && (
              <div className="row bordered">
                <div className="col-md-12">
                  <Label>Please enter customer details:</Label>
                  <input
                    type="text"
                    className="form-control"
                    id="customerName"
                    placeholder="customer name"
                    {...register('customerName', { required: true })}
                    data-testid="customerName"
                    onChange={e => setValue('customerName', e.target.value)}
                  />
                  {errors.customerName && <ErrorText>This field is required</ErrorText>}
                </div>
                <label className="footer-text">
                  This field must be completed with the full legal entity name on the evaluation contract.
                </label>
                <div className="col-md-6 pt-2">
                  <input
                    className="form-check-input mr-3"
                    type="checkbox"
                    value=""
                    id="availableForDD"
                    data-testid="availableForDD"
                    {...register('availableForDD')}
                  />
                  <label className="form-check-label grey-checkbox-text" htmlFor="availableForDD">
                    Is available for GBG Data Network (Trust)
                  </label>
                </div>
                <label className="footer-text">
                  Please check CRM. This box must only be ticked if the customer has opted into GBG Data.
                </label>
              </div>
            )}
          </div>
        </div>
      )}
      <ModalFooter
        handleAction={(event: any) => {
          onSubmit();
          event.preventDefault();
        }}
        actionLabel="Submit"
        actionType={ButtonKind.Primary}
        isSubmitting={isSubmitting}
        disabled={false}
        cancelLabel="Cancel"
      />
    </form>
  );
};

export default memo(SubmitToSipForm);
