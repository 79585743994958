import { ToastPosition, ToastType } from '@gbg/gbgcomponentlibrary_react';

export const TOAST_MSG = (
  message = 'The content has been updated',
  title = 'Success!',
  type: ToastType = ToastType.Success,
) => ({
  title: title,
  content: message,
  duration: 5000,
  position: ToastPosition.TopRight,
  type: type,
  dismissOthers: false,
  dismissable: true,
});
