import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Job, JobStatus } from '../../models/job';
import Table from '../../components/table/Table';
import { AlertBarType, ButtonKind } from '@gbg/gbgcomponentlibrary_react';
import { useAppDispatch } from '../../app/hooks';
import { openModal } from '../../features/modal/modalSlice';
import {
  useDeleteJobMutation,
  useGetJobsQuery,
  useLazyGetDownloadResultsUrlQuery,
  usePutProcessStatusMutation,
} from '../../api/jobs';
import SubmitToSipForm from './SubmitToSipForm';

export const JobList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const headers = ['fileDisplayName', 'status', 'createdAt'];
  const { data: jobs, isFetching } = useGetJobsQuery();
  const [getDownloadResultsUrl] = useLazyGetDownloadResultsUrlQuery();
  const [putProcessStatus, { isLoading: isPutProcessLoading }] = usePutProcessStatusMutation();
  const [deleteJob, { isLoading: isDeleteLoading }] = useDeleteJobMutation();

  const onPreflightClicked = useCallback(
    (item: Job) => {
      navigate(`/preflight/${item.jobId}`, { state: { jobId: item.jobId } });
    },
    [navigate],
  );

  const createModalFooter = (handleAction: any, actionLabel: string, isSubmitting: boolean, cancelLabel = '') => ({
    handleAction: handleAction,
    actionLabel: actionLabel,
    actionType: ButtonKind.Primary,
    isSubmitting: isSubmitting,
    cancelLabel: cancelLabel,
  });

  const onSubmitSip = useCallback(
    (item: Job) => {
      dispatch(
        openModal({
          content: (
            <SubmitToSipForm job={item} putProcessStatus={putProcessStatus} isSubmitting={isPutProcessLoading} />
          ),
          type: AlertBarType.Warn,
          header: ` Submit ${item.fileDisplayName} for processing?`,
          renderModalFooter: false,
          modalClass: 'half-size-modal',
        }),
      );
    },
    [dispatch],
  );

  const onCancel = useCallback(
    (item: Job) => {
      dispatch(
        openModal({
          content: `Are you sure you want to cancel '${item.fileDisplayName}'?`,
          type: AlertBarType.Warn,
          header: 'Cancel Job',
          modalFooter: createModalFooter(
            () => {
              const data = { jobId: item.jobId, status: JobStatus.canceled, mode: '' };
              putProcessStatus(data);
            },
            'Yes',
            isPutProcessLoading,
            'No',
          ),
        }),
      );
    },
    [dispatch, createModalFooter, putProcessStatus, isPutProcessLoading],
  );

  const onDelete = useCallback(
    (item: Job) => {
      dispatch(
        openModal({
          content: `Are you sure you want to delete '${item.fileDisplayName}'?`,
          type: AlertBarType.Warn,
          header: 'Delete Job',
          modalFooter: createModalFooter(
            () => {
              deleteJob({ jobId: item.jobId });
            },
            'Remove',
            isDeleteLoading,
            'Cancel',
          ),
        }),
      );
    },
    [dispatch, createModalFooter, deleteJob, isDeleteLoading],
  );

  const onDownloadResults = useCallback(
    (job: Job) => {
      getDownloadResultsUrl({ jobId: job.jobId })
        .unwrap()
        .then(url => {
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.download = 'results.jsonl';

          link.click();
        });
    },
    [getDownloadResultsUrl],
  );

  return (
    <Table
      title="Jobs"
      addUrl="/jobs/add"
      addButtonText="New Job"
      {...{
        headers,
        data: jobs ? { results: jobs, totalPages: 1, pageSize: jobs?.length, pageIndex: 1 } : undefined,
        isFetching: isFetching,
        actions: (item: Job): any => {
          const availableActions = [];
          if (item.status === JobStatus.mapping) {
            availableActions.push({
              title: 'Preflight',
              action: onPreflightClicked,
            });
          }

          if (item.status === JobStatus.pre_flight_complete) {
            availableActions.push({
              title: 'Submit to SIP',
              action: onSubmitSip,
            });
          }

          if (item.status === JobStatus.done) {
            availableActions.push({
              title: 'Download Results',
              action: onDownloadResults,
            });
          }

          if (item.status === JobStatus.pre_flight_complete) {
            availableActions.push({
              title: 'Preflight Results',
              action: () => {
                navigate(`/preflight/${item.jobId}/results`);
              },
            });
          }

          if (item.status === JobStatus.pre_flight_requested || item.status === JobStatus.evaluate_processing) {
            availableActions.push({
              title: 'Cancel',
              action: onCancel,
            });
          }

          if (item.status !== JobStatus.pre_flight_requested && item.status !== JobStatus.evaluate_processing) {
            availableActions.push({
              title: 'Delete',
              action: onDelete,
              danger: true,
            });
          }

          return availableActions;
        },
      }}
    />
  );
};

export default memo(JobList);
