import { RouteObject, createBrowserRouter } from 'react-router-dom';

import AddJob from '../pages/jobs/AddJob';
import JobList from '../pages/jobs/JobList';
import PreflightJob from '../pages/jobs/PreflightJob';
import PreflightResults from '../pages/jobs/PreflightResults';

export const routesList: RouteObject[] = [
  {
    path: '/jobs',
    element: <JobList />,
  },
  { path: '/jobs/add', element: <AddJob /> },
  {
    path: '/preflight/:jobId',
    element: <PreflightJob />,
  },
  {
    path: '/preflight/:jobId/results',
    element: <PreflightResults />,
  },
];

const routes = createBrowserRouter(routesList);

export default routes;
