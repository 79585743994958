import { Spinner } from '@gbg/gbgcomponentlibrary_react';
import { memo, useState } from 'react';

export interface DraggableFileInputProps {
  handleFileUploadChange: (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent) => Promise<false | undefined>;
  name: string;
  inputRef: React.RefObject<HTMLInputElement>;
  acceptableTypes: string;
  isUploading: boolean;
  className?: string;
}
const DraggableFileInput = ({
  handleFileUploadChange,
  name,
  inputRef,
  acceptableTypes,
  isUploading,
  className,
}: DraggableFileInputProps) => {
  const [dragActive, setDragActive] = useState(false);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent) => {
    if (!inputRef.current?.files) return;
    setDragActive(false);
    await handleFileUploadChange(e);
    setDragActive(true);
  };
  // handle drag events
  const handleDrag = function (e: React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  return (
    <div
      className={`${className ?? ''} draggable-file-container`}
      data-testid="draggable-input"
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
    >
      <input
        ref={inputRef}
        type="file"
        id={name}
        onChange={handleChange}
        data-testid="input-file"
        accept={acceptableTypes}
        className="draggable-input"
      />
      <label id={`${name}-label`} htmlFor={name} className={dragActive ? 'drag-active' : ''} data-testid="drag-here">
        <div>
          <p>Drag and drop your file here or</p>

          <button
            className="upload-button btn btn-primary"
            onClick={() => inputRef.current?.click()}
            disabled={isUploading}
            data-testid="btn-upload"
          >
            {isUploading && <Spinner />} Upload a file
          </button>
        </div>
      </label>
      {dragActive && (
        <div
          data-testid="draggable-area"
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleChange}
        ></div>
      )}
    </div>
  );
};

export default memo(DraggableFileInput);
