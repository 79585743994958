import React, { useEffect } from 'react';
import { FieldErrors, FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Label, ErrorText, FormGroup } from '@gbg/gbgcomponentlibrary_react';

interface ITextInput {
  labelText: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  defaultValue?: string;
  setValue: UseFormSetValue<FieldValues>;
  required?: boolean;
  disabled?: boolean;
  errors: FieldErrors<FieldValues>;
}

const TextInput: React.FC<ITextInput> = ({
  name,
  labelText,
  register,
  defaultValue = '',
  setValue,
  required = false,
  disabled = false,
  errors,
}) => {
  useEffect(() => {
    setValue(`${name}` as const, defaultValue);
  }, [defaultValue]);

  const inputRegister = register(`${name}` as const, {
    required,
    onBlur: event => {
      const value = event.target.value;
      if (value) setValue(`${name}` as const, value.trim());
    },
  });

  return (
    <FormGroup className="form-floating">
      <input type="text" {...inputRegister} disabled={disabled} data-testid={name} className="form-control"></input>
      <Label>
        <div>
          <span className={required ? 'required' : ''}>{labelText}</span>
        </div>
      </Label>
      {errors[name] && (
        <ErrorText>{errors[name]?.message ? errors[name]?.message : <p>This field is required</p>}</ErrorText>
      )}
    </FormGroup>
  );
};

export default TextInput;
