import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { ModalState } from './config';
import { AlertBarType, ButtonKind } from '@gbg/gbgcomponentlibrary_react';

const initialState: ModalState = {
  isOpen: false,
  header: '',
  content: '',
  type: AlertBarType.Warn,
  modalFooter: undefined,
  renderModalFooter: true,
  modalClass: '',
};

export interface ModalFooterProps {
  handleAction?: any;
  actionLabel?: string;
  actionType?: ButtonKind;
  isSubmitting?: boolean;
  handleActionData?: any;
  disabled?: boolean;
  cancelLabel?: string;
}

export const handleModalAction = createAsyncThunk(
  '/modal/handleAction',
  async ({
    handleActionData,
    handleAction,
  }: {
    handleActionData: any;
    handleAction: ModalFooterProps['handleAction'];
  }) => {
    if (handleAction) {
      const result = handleAction(handleActionData);
      return result;
    }
  },
);
export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        content: any;
        header: string;
        type?: AlertBarType;
        modalFooter?: ModalFooterProps;
        renderModalFooter?: boolean;
        modalClass?: string;
      }>,
    ) => {
      state.isOpen = true;
      state.content = action.payload.content;
      state.header = action.payload.header;
      state.type = action.payload.type ?? AlertBarType.Warn;
      state.modalFooter = action.payload.modalFooter;
      state.renderModalFooter = action.payload.renderModalFooter ?? true;
      state.modalClass = action.payload.modalClass ?? '';
    },
    closeModal: state => {
      state.isOpen = false;
      state.content = '';
      state.header = '';
    },
    updateModalFooterState: (state, action: PayloadAction<ModalFooterProps>) => {
      state.modalFooter = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(handleModalAction.fulfilled, state => {
      state.isOpen = false;
      state.content = '';
      state.header = '';
    });
  },
});

export const { openModal, closeModal, updateModalFooterState } = modalSlice.actions;

export const selectModal = (state: RootState): ModalState => state.modal;

export default modalSlice.reducer;
