import { memo } from 'react';
import { useGetJobQuery, useLazyGetDownloadPreflightResultsUrlQuery } from '../../api/jobs';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ButtonKind, Icon, IconKeys, Spinner } from '@gbg/gbgcomponentlibrary_react';

export const PreflightResults = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const [getPreflightResultUrl] = useLazyGetDownloadPreflightResultsUrlQuery();
  const { data: job, isFetching } = useGetJobQuery({ jobId: jobId ?? '' }, { skip: !jobId });

  const navigate = useNavigate();

  const onDownloadResults = () => {
    if (!job?.jobId) return;
    getPreflightResultUrl({ jobId: job.jobId })
      .unwrap()
      .then(url => {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = 'results.jsonl';
        link.click();
      });
  };

  return isFetching ? (
    <Spinner />
  ) : (
    <div className="pre-flight-result">
      <div className="job-name">
        <p> Preflight Results for </p>
        <b data-testid="job-name">{job?.fileDisplayName}</b>
      </div>
      <hr className="hr" />
      <div className="row container mt-4  mb-4">
        <div className="col-md-4" data-testid="valid-records">
          <div className="title">
            <Icon icon={IconKeys.Check} className="text-success"></Icon>
            <p className="title-text">Number of Valid Records:</p>
            <p className="stats" data-testid="valid-records-result">
              {job?.preFlightResult?.numberOfValidRecords}
            </p>
          </div>
        </div>
        <div className="col-md-4" data-testid="invalid-records">
          <div className="title">
            <Icon icon={IconKeys.AlertTriangle} className="text-danger"></Icon>
            <p className="title-text">Number of Invalid Records:</p>
            <p className="stats" data-testid="invalid-records-result">
              {job?.preFlightResult?.numberOfInvalidRecords}
            </p>
          </div>
        </div>
        <div className="col-md-4" data-testid="unmappable-records">
          <div className="title">
            <Icon icon={IconKeys.AlertCircle} className="text-info"></Icon>
            <p className="title-text">Number of Unmappable Records:</p>
            <p className="stats" data-testid="unmappable-records-result">
              {job?.preFlightResult?.numberOfUnmappableRecords}
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="dol-md-3">
          <Button type="button" kind={ButtonKind.Secondary} className="float-end" onClick={() => navigate('/jobs')}>
            Back
          </Button>

          {((job?.preFlightResult?.numberOfInvalidRecords ?? 0) > 0 ||
            (job?.preFlightResult?.numberOfUnmappableRecords ?? 0) > 0) && (
            <Button type="button" kind={ButtonKind.Primary} data-testid="download-results" onClick={onDownloadResults}>
              Download Results
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default memo(PreflightResults);
