import { Spinner } from '@gbg/gbgcomponentlibrary_react';

const CustomSpinner = () => {
  return (
    <div className="spinner-container">
      <div className="custom-spinner">
        <Spinner data-testid="spinner" />
        <p>Loading ...</p>
      </div>
    </div>
  );
};

export default CustomSpinner;
