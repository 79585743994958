import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import pingReducer from '../features/ping/pingSlice';
import errorReducer from '../features/error/errorSlice';
import { evaluatorApi, builderApi } from '../api/apiBase';
import modalReducer from '../features/modal/modalSlice';
import storage from 'redux-persist/lib/storage';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['evaluatorApi', 'modal', 'builderApi'],
};

export const store = configureStore({
  reducer: persistReducer(
    persistConfig,
    combineReducers({
      ping: pingReducer,
      error: errorReducer,
      modal: modalReducer,
      [evaluatorApi.reducerPath]: evaluatorApi.reducer,
      [builderApi.reducerPath]: builderApi.reducer,
    }),
  ),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(evaluatorApi.middleware)
      .concat(builderApi.middleware),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
