import { Job } from '../models/job';
import { evaluatorApi } from './apiBase';

const endpoint = '/jobs';

const jobService = evaluatorApi.injectEndpoints({
  endpoints: build => ({
    getJobs: build.query<Job[], void>({
      query: () => ({
        url: `${endpoint}`,
        method: 'GET',
      }),
      providesTags: results =>
        results
          ? [...results.map(item => ({ type: 'Job', id: item.jobId } as const)), { type: 'Job', id: 'LIST' }]
          : [{ type: 'Job', id: 'LIST' }],
    }),

    generateUploadUrl: build.mutation<{ url: string; jobId: string }, { fileName: string; fileDisplayName: string }>({
      query: ({ fileName, fileDisplayName }) => ({
        url: `/create-upload-url/${fileDisplayName}`,
        method: 'POST',
        data: { fileName },
      }),
      invalidatesTags: [{ type: 'Job', id: 'LIST' }],
    }),

    createJob: build.mutation<Job, { fileName: string; fileDisplayName: string; jobId: string }>({
      query: data => ({
        url: `${endpoint}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'Job', id: 'LIST' }],
    }),

    deleteJob: build.mutation<Job, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `${endpoint}/${jobId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Job', id: arg.jobId }],
    }),

    putDatasetMappings: build.mutation<void, { jobId: string; mappings: object }>({
      query: ({ jobId, mappings }) => ({
        url: `${endpoint}/${jobId}/mappings/dataset`,
        method: 'PUT',
        data: mappings,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Job', id: arg.jobId }],
    }),

    putProcessStatus: build.mutation<
      string,
      {
        jobId: string;
        status: string;
        datasetValidationSchema?: string;
        mode?: string;
        auditConfig?: { dataSource: string; customerName: string; availableForDD: boolean };
      }
    >({
      query: ({ jobId, status, datasetValidationSchema, mode, auditConfig }) => ({
        url: `${endpoint}/${jobId}/status`,
        method: 'PUT',
        data: { status, datasetValidationSchema, mode, auditConfig },
      }),
      invalidatesTags: jobId => (jobId ? [{ type: 'Job', id: jobId }] : []),
    }),
    getJob: build.query<Job, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `${endpoint}/${jobId}`,
        method: 'GET',
        providesTags: (job: Job) => (job ? [{ type: 'Job', id: job.jobId }] : []),
      }),
    }),
    getDownloadResultsUrl: build.query<string, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `${endpoint}/${jobId}/results`,
        method: 'GET',
      }),
    }),
    getDownloadPreflightResultsUrl: build.query<string, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `${endpoint}/${jobId}/pre-flight/results`,
        method: 'GET',
      }),
    }),
  }),
});
export const {
  useGetJobsQuery,
  useGenerateUploadUrlMutation,
  useCreateJobMutation,
  usePutDatasetMappingsMutation,
  usePutProcessStatusMutation,
  useDeleteJobMutation,
  useGetJobQuery,
  useLazyGetDownloadResultsUrlQuery,
  useLazyGetDownloadPreflightResultsUrlQuery,
} = jobService;
