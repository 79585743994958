import { memo } from 'react';
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import TextInput from './TextInput';
import { ValidationSchemaJSON } from '../../models/Dataset';
import CustomSpinner from '../CustomSpinner';

export interface DatasetMappingSectionProps {
  data?: ValidationSchemaJSON;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  // TODO: Fix the type here
  errors: any;
  isFetching: boolean;
}

const DatasetMappingSection: React.FC<DatasetMappingSectionProps> = ({
  data,
  register,
  setValue,
  errors,
  isFetching,
}: DatasetMappingSectionProps) => {
  const credentialsData = data?.properties?.datasets?.items?.properties?.credentials?.properties
    ? Object.keys(data?.properties?.datasets?.items?.properties?.credentials?.properties)
    : [];
  const customAttributesData = data?.properties?.datasets?.items?.properties?.customAttributes?.properties
    ? Object.keys(data?.properties?.datasets?.items?.properties?.customAttributes?.properties)
    : [];

  const buildInputs = (inputs: string[], inputTypes: string) => {
    return inputs.map(keyName => (
      <div key={uuid()} className="col-md-6 col-xs-12">
        <TextInput
          labelText={keyName}
          // FIXME: SIP-1119 This creates an appropriate structure however stops validation from working,
          // if just keyName is passed the validation is working but the data structure is incorrect
          name={`${inputTypes}.${keyName}`}
          register={register}
          setValue={setValue}
          required={true}
          errors={errors}
        />
      </div>
    ));
  };

  return isFetching ? (
    <CustomSpinner />
  ) : (
    <>
      {credentialsData.length > 0 && (
        <div className="form-input-group">
          <div className="input-group-title">
            <div className="title-container">
              <span className="title">Credentials</span>
            </div>
          </div>
          <div className="row">{buildInputs(credentialsData, 'credentials')}</div>
        </div>
      )}
      {customAttributesData.length > 0 && (
        <div className="form-input-group">
          <div className="input-group-title">
            <div className="title-container">
              <span>Custom Attributes</span>
            </div>
            <div className="row"> {buildInputs(customAttributesData, 'customAttributes')}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(DatasetMappingSection);
